
import http from "../../../../../common/services/http";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const search = ref("");
    const headers = {
      name: "Product Name",
      variety: "Crops",
      cost: "Raw Cost",
      loose: "L. Loose",
      prepack: "L. Prepack",
      packability: "Packability"
    };

    const is_compound = ref(false);

    const records = ref({
      data: []
    });

    const varieties = ref([]);

    const record = reactive({
      name: "",
      code: "",
      cost: 0,
      loose: 0,
      prepack: 0,
      variety_id: 0,
      packability: 0,
      varieties: [{ variety_id: "", percentage: 0, cost: 0 }]
    });

    const product = ref({
      name: "",
      code: "",
      cost: 0,
      loose: 0,
      prepack: 0,
      variety_id: 0,
      packability: 0,
      varieties: [{ variety_id: "", percentage: 0, cost: 0 }]
    });

    const selected = ref([]);

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const dark = computed(() => false);
    //window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)

    function changeCropToggle() {
      is_compound.value = !is_compound.value;
    }

    function addRep(u: any) {
      u.push({ variety_id: "", percentage: 0, cost: 0 });
    }

    function delRep(u: any, i: any) {
      u.splice(i, 1);
    }

    function editRecord(p: any) {
      if (p.varieties == null) {
        p.varieties = [{ variety_id: "", percentage: 0, cost: 0 }];
      }
      product.value = p;
    }

    function fetchRecords(page = 1) {
      processing.value = true;
      http.get(`/api/products?page=${page}`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    async function fetchVarieties() {
      processing.value = true;
      http.get(`/api/varieties?fetch=all`).then(res => {
        varieties.value = res.data;
        processing.value = false;
      });

      return varieties.value;
    }

    function searchRecords() {
      http.get(`/api/products?s=${search.value}`).then(res => {
        records.value = res.data;
      });
    }

    function createRecord() {
      http
        .post("/api/products", record)
        .then(res => {
          if (res.data.success) {
            created.value = true;

            record.name = "";
            record.code = "";
            record.cost = 0;
            record.loose = 0;
            record.prepack = 0;
            record.packability = 0;
            record.variety_id = 0;

            document.getElementById("hideNewForm")?.click();
          }
        })
        .finally(() => {
          document.getElementById("hideNewForm")?.click();
          fetchRecords();
        });
    }

    function updateRecord(record: any) {
      record._method = "PUT";
      http
        .post(`/api/products/${record.id}`, record)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchRecords();
        });
    }

    function deleteRecord(r: any) {
      http.post(`/api/products/${r.id}`, { _method: "DELETE" }).then(res => {
        const rs = records.value.data;
        //rs.splice(rs.indexOf(r), 1)
        records.value.data = rs.filter((p: any) => p.id !== r.id);
      });
    }

    onMounted(() => {
      fetchVarieties().then(() => {
        fetchRecords();
      });
    });

    function setSelected(s: any) {
      selected.value = s;
    }
    function clearSelection() {
      selected.value = [];
    }
    function formatLabel(item: any, key = "id") {
      if (typeof item === "object" && item !== null) {
        if (item.name) {
          return item.name;
        } else if (item.title) {
          return item.title;
        }
      } else {
        if (key == "created_at" || key == "updated_at") {
          return format(new Date(item), "PPPP");
        } else {
          return item;
        }
      }
    }

    return {
      headers,
      fetchRecords,
      processing,
      records,
      createRecord,
      created,
      record,
      updateRecord,
      updated,
      deleteRecord,
      setSelected,
      selected,
      search,
      searchRecords,
      clearSelection,
      varieties,
      formatLabel,
      dark,
      is_compound,
      changeCropToggle,
      addRep,
      delRep,
      editRecord,
      product
    };
  }
});
