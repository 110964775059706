<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des d-print-none">
            <p>
              Showing <b>{{ records?.from || 0 }}</b> -
              <b>{{ records?.to || 0 }}</b> of
              <b>{{ records?.total || 0 }}</b> products
            </p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li v-if="!selected.length">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control d-print-none"
                  placeholder="Search Product"
                  v-model="search"
                  @keyup="searchRecords"
                />
              </div>
            </li>
            <li>
              <a
                href="#newItem"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-plus-c"></em>
                <span>
                  <span class="d-none d-sm-inline-block">
                    Add
                  </span>
                  New
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="newItem">
                <div class="modal-dialog modal-lg" role="document">
                  <form class="modal-content" @submit.prevent="createRecord">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white" v-if="record.name">
                        Adding {{ record.name }}
                      </h5>
                      <h5 class="modal-title text-white" v-else>
                        Add New Product
                      </h5>
                    </div>

                    <div class="modal-body form row">
                      <div class="form-group col-md-6">
                        <label>
                          Product Name
                        </label>
                        <input class="form-control" v-model="record.name" />
                      </div>

                      <div class="form-group col-md-4">
                        <label>Product Code</label>
                        <input class="form-control" v-model="record.code" />
                      </div>

                      <div
                        class="form-group custom-control custom-switch col-md-12 ml-4"
                        style="margin-top: 2rem;"
                      >
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitch1"
                          @click="changeCropToggle"
                        />
                        <label class="custom-control-label" for="customSwitch1"
                          >Multiple Crops</label
                        >
                      </div>
                      <div>
                        <div v-if="is_compound">
                          <div
                            v-for="(rep, r) in record.varieties"
                            :key="r"
                            class="row ml-2"
                          >
                            <div class="form-group col-4">
                              <label>
                                Crop
                              </label>
                              <Multiselect
                                :options="varieties"
                                v-model="record.varieties[r].variety_id"
                                trackBy="name"
                                label="name"
                                valueProp="id"
                                :searchable="true"
                                placeholder="Search Crop..."
                              />
                            </div>
                            <div class="form-group col-3">
                              <label>Percentage</label>
                              <input
                                class="form-control"
                                v-model="record.varieties[r].percentage"
                              />
                            </div>
                            <div class="form-group col-3">
                              <label> RM Cost</label>
                              <input
                                class="form-control"
                                v-model="record.varieties[r].cost"
                              />
                            </div>
                            <div class="form-group col-md-3">
                              <label> Labor Target Loose</label>
                              <input
                                class="form-control"
                                v-model="record.varieties[r].loose"
                              />
                            </div>
                            <div class="form-group col-md-3">
                              <label>
                                Labor Target Prepack
                              </label>
                              <input
                                class="form-control"
                                v-model="record.varieties[r].prepack"
                              />
                            </div>
                            <div class="form-group col-md-3">
                              <label>Packability Target</label>
                              <input
                                class="form-control"
                                v-model="record.varieties[r].packability"
                              />
                            </div>
                            <div class="form-group align-self-center">
                              <button
                                class="btn btn-icon btn-round btn-danger mr-1"
                                v-if="r > 0"
                                @click.prevent="delRep(record.varieties, r)"
                              >
                                <em class="icon ni ni-minus"></em>
                              </button>
                              <button
                                class="btn btn-icon btn-round btn-primary"
                                v-if="r == record.varieties.length - 1"
                                @click.prevent="addRep(record.varieties)"
                              >
                                <em class="icon ni ni-plus"></em>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div class="row">
                            <div class="form-group col-md-4">
                              <label>
                                Crop
                              </label>
                              <Multiselect
                                :options="varieties"
                                v-model="record.variety_id"
                                trackBy="name"
                                label="name"
                                valueProp="id"
                                :searchable="true"
                                placeholder="Type to search..."
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label>
                                Raw Material Costs
                              </label>
                              <input
                                class="form-control"
                                v-model="record.cost"
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label> Labor Target Loose</label>
                              <input
                                class="form-control"
                                v-model="record.loose"
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label>
                                Labor Target Prepack
                              </label>
                              <input
                                class="form-control"
                                v-model="record.prepack"
                              />
                            </div>
                            <div class="form-group col-md-4">
                              <label>Packability Target</label>
                              <input
                                class="form-control"
                                v-model="record.packability"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="modal-footer bg-light justify-content-end">
                      <button class="btn btn-primary float-right">
                        <em class="icon ni ni-save"></em>
                        <span>
                          Save Details
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card card-bordered" v-else>
        <div class="card-header" v-if="selected.length">
          <div class="row">
            <div class="col align-middle">
              <input
                type="text"
                class="form-control d-print-none"
                placeholder="Search Product"
                v-model="search"
                @keyup="searchRecords"
              />
            </div>
            <div class="col"></div>
            <div class="col">
              <a
                href="#"
                class="btn btn-primary btn-icon btn-round"
                @click.prevent="clearSelection"
              >
                <em class="icon ni ni-cross"></em>
              </a>
              <div class="drodown float-right">
                <a
                  href="#"
                  class="dropdown-toggle btn btn-dim btn-outline-primary"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span>
                    <span class="d-none d-md-inline">With</span>
                    {{ selected.length - 1 }} Selected
                  </span>
                  <em class="dd-indc icon ni ni-chevron-right"></em>
                </a>
                <div class="dropdown-menu dropdown-menu-right" style="">
                  <ul class="link-list-opt no-bdr">
                    <li>
                      <a href="#" @click.prevent="createRecord">
                        <span>
                          Do Something
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="table-responsive pmd-table-card">
          <table
            class="table pmd-table table-orders"
            :class="{ 'table-light': !dark, 'table-dark': dark }"
          >
            <thead
              class="tb-odr-head"
              :class="{ 'thead-dark': dark }"
              v-if="records.data.length"
            >
              <tr class="tb-odr-item">
                <th class="tb-tnx-info align-middle" style="min-width: 250px">
                  Product
                </th>

                <th class="tb-tnx-info align-middle" style="min-width: 205px">
                  Crops
                </th>

                <th class="text-truncate align-middle">
                  Raw Cost
                </th>

                <th class="text-truncate align-middle">
                  L. Loose
                </th>

                <th class="text-truncate align-middle">
                  L. Prepack
                </th>

                <th class="text-truncate align-middle">
                  Packability
                </th>
                <th class="tb-odr-action d-print-none" colspan="2">
                  &nbsp;
                </th>
              </tr>
            </thead>

            <tbody class="tb-odr-body">
              <template v-if="records.data.length > 0">
                <tr
                  class="tb-odr-item"
                  v-for="record in records.data"
                  :key="record.id"
                >
                  <td
                    class="tb-tnx-info"
                    style="min-width: 250px"
                    data-title="Product"
                  >
                    <input
                      class="form-control border-0"
                      v-model="record.name"
                    />
                  </td>

                  <td
                    class="tb-tnx-info align-middle"
                    style="min-width: 205px"
                    data-title="Variety"
                  >
                    <div class="form-group" v-if="record.variety_id !== null">
                      <Multiselect
                        class="border-0"
                        :options="varieties"
                        v-model="record.variety_id"
                        trackBy="name"
                        label="name"
                        valueProp="id"
                        :searchable="true"
                        placeholder="Type to search..."
                      />
                    </div>
                    <a
                      v-else
                      href="#editCrop"
                      @click="editRecord(record)"
                      data-toggle="modal"
                      class="btn btn-white btn-dim btn-outline-primary"
                    >
                      <em class="icon ni ni-pen-fill"></em>
                      <span>
                        <span class="d-none d-sm-inline-block">
                          Edit
                        </span>
                        Crops
                      </span>
                    </a>
                  </td>

                  <td data-title="Raw Material Cost">
                    <div class="form-control-wrap">
                      <input
                        class="form-control border-0"
                        v-model="record.cost"
                      />
                    </div>
                  </td>

                  <td data-title="Labor Target Loose">
                    <div class="form-control-wrap">
                      <input
                        class="form-control border-0"
                        v-model="record.loose"
                      />
                    </div>
                  </td>

                  <td data-title="Labor Target Prepack">
                    <div class="form-control-wrap">
                      <input
                        class="form-control border-0"
                        v-model="record.prepack"
                      />
                    </div>
                  </td>

                  <td data-title="Packability Target">
                    <div class="form-control-wrap" style="max-width: 74px;">
                      <div class="form-text-hint">
                        <span class="overline-title">
                          %
                        </span>
                      </div>
                      <input
                        class="form-control border-0"
                        v-model="record.packability"
                      />
                    </div>
                  </td>

                  <td
                    class="tb-tnx-action justify-content-center d-lg-flex d-print-none align-middle"
                    colspan="2"
                  >
                    <button
                      @click.prevent="updateRecord(record)"
                      class="btn btn-icon btn-round btn-outline-primary btn-sm"
                    >
                      <em class="icon ni ni-save"></em>
                    </button>
                    <button
                      @click.prevent="deleteRecord(record)"
                      class="btn btn-icon btn-round btn-outline-danger btn-sm ml-1 mr-2"
                    >
                      <em class="icon ni ni-trash-empty"></em>
                    </button>
                  </td>
                </tr>
              </template>

              <template v-else>
                <tr class="tb-odr-item alert alert-danger">
                  <td
                    :colspan="headers.length + 1"
                    class="align-middle text-center"
                  >
                    <h4>
                      <em class="icon ni ni-alert-c text-danger mr-2"></em>
                      No record to display...
                    </h4>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>

      <paginate
        :data="records"
        @changed="fetchRecords"
        v-if="records.data.length"
      />
    </div>
  </div>
  <div
    class="modal fade"
    id="editCrop"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <form class="modal-content" @submit.prevent="updateRecord(product)">
        <div class="modal-header bg-primary">
          <h5 class="modal-title text-white">Edit {{ product.name }}</h5>

          <button
            id="hideEditForm"
            class="close"
            type="button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <em class="icon ni ni-eye-off text-white"></em>
          </button>
        </div>
        <div class="modal-body text-left">
          <div
            v-for="(rep, r) in product.varieties"
            :key="r"
            class="row border-bottom py-3"
          >
            <div class="form-group col-4">
              <label>
                Crop
              </label>
              <Multiselect
                :options="varieties"
                v-model="product.varieties[r].variety_id"
                trackBy="name"
                label="name"
                valueProp="id"
                :searchable="true"
                placeholder="Type to search..."
              />
            </div>
            <div class="form-group col-3">
              <label>Percentage</label>
              <input
                class="form-control"
                v-model="product.varieties[r].percentage"
              />
            </div>
            <div class="form-group col-3">
              <label> RM Cost</label>
              <input class="form-control" v-model="product.varieties[r].cost" />
            </div>

            <div class="form-group col-md-3">
              <label> Labor Target Loose</label>
              <input
                class="form-control"
                v-model="product.varieties[r].loose"
              />
            </div>
            <div class="form-group col-md-3">
              <label>
                Labor Target Prepack
              </label>
              <input
                class="form-control"
                v-model="product.varieties[r].prepack"
              />
            </div>
            <div class="form-group col-md-3">
              <label>Packability Target</label>
              <input
                class="form-control"
                v-model="product.varieties[r].packability"
              />
            </div>
            <div class="form-group align-self-center ml-4">
              <button
                class="btn btn-icon btn-round btn-danger mr-1"
                v-if="r > 0"
                @click.prevent="delRep(product.varieties, r)"
              >
                <em class="icon ni ni-minus"></em>
              </button>
              <button
                class="btn btn-icon btn-round btn-primary"
                v-if="r == product.varieties.length - 1"
                @click.prevent="addRep(product.varieties)"
              >
                <em class="icon ni ni-plus"></em>
              </button>
            </div>
          </div>
        </div>
        <div class="modal-footer bg-light justify-content-end">
          <button class="btn btn-primary float-right">
            <em class="icon ni ni-save"></em>
            <span>
              Update Crop Details
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { format } from "date-fns";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const search = ref("");
    const headers = {
      name: "Product Name",
      variety: "Crops",
      cost: "Raw Cost",
      loose: "L. Loose",
      prepack: "L. Prepack",
      packability: "Packability"
    };

    const is_compound = ref(false);

    const records = ref({
      data: []
    });

    const varieties = ref([]);

    const record = reactive({
      name: "",
      code: "",
      cost: 0,
      loose: 0,
      prepack: 0,
      variety_id: 0,
      packability: 0,
      varieties: [{ variety_id: "", percentage: 0, cost: 0 }]
    });

    const product = ref({
      name: "",
      code: "",
      cost: 0,
      loose: 0,
      prepack: 0,
      variety_id: 0,
      packability: 0,
      varieties: [{ variety_id: "", percentage: 0, cost: 0 }]
    });

    const selected = ref([]);

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const dark = computed(() => false);
    //window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)

    function changeCropToggle() {
      is_compound.value = !is_compound.value;
    }

    function addRep(u: any) {
      u.push({ variety_id: "", percentage: 0, cost: 0 });
    }

    function delRep(u: any, i: any) {
      u.splice(i, 1);
    }

    function editRecord(p: any) {
      if (p.varieties == null) {
        p.varieties = [{ variety_id: "", percentage: 0, cost: 0 }];
      }
      product.value = p;
    }

    function fetchRecords(page = 1) {
      processing.value = true;
      http.get(`/api/products?page=${page}`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    async function fetchVarieties() {
      processing.value = true;
      http.get(`/api/varieties?fetch=all`).then(res => {
        varieties.value = res.data;
        processing.value = false;
      });

      return varieties.value;
    }

    function searchRecords() {
      http.get(`/api/products?s=${search.value}`).then(res => {
        records.value = res.data;
      });
    }

    function createRecord() {
      http
        .post("/api/products", record)
        .then(res => {
          if (res.data.success) {
            created.value = true;

            record.name = "";
            record.code = "";
            record.cost = 0;
            record.loose = 0;
            record.prepack = 0;
            record.packability = 0;
            record.variety_id = 0;

            document.getElementById("hideNewForm")?.click();
          }
        })
        .finally(() => {
          document.getElementById("hideNewForm")?.click();
          fetchRecords();
        });
    }

    function updateRecord(record: any) {
      record._method = "PUT";
      http
        .post(`/api/products/${record.id}`, record)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchRecords();
        });
    }

    function deleteRecord(r: any) {
      http.post(`/api/products/${r.id}`, { _method: "DELETE" }).then(res => {
        const rs = records.value.data;
        //rs.splice(rs.indexOf(r), 1)
        records.value.data = rs.filter((p: any) => p.id !== r.id);
      });
    }

    onMounted(() => {
      fetchVarieties().then(() => {
        fetchRecords();
      });
    });

    function setSelected(s: any) {
      selected.value = s;
    }
    function clearSelection() {
      selected.value = [];
    }
    function formatLabel(item: any, key = "id") {
      if (typeof item === "object" && item !== null) {
        if (item.name) {
          return item.name;
        } else if (item.title) {
          return item.title;
        }
      } else {
        if (key == "created_at" || key == "updated_at") {
          return format(new Date(item), "PPPP");
        } else {
          return item;
        }
      }
    }

    return {
      headers,
      fetchRecords,
      processing,
      records,
      createRecord,
      created,
      record,
      updateRecord,
      updated,
      deleteRecord,
      setSelected,
      selected,
      search,
      searchRecords,
      clearSelection,
      varieties,
      formatLabel,
      dark,
      is_compound,
      changeCropToggle,
      addRep,
      delRep,
      editRecord,
      product
    };
  }
});
</script>
